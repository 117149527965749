.loader {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    --w: 10ch;
    font-weight: bold;
    font-family: monospace;
    font-size: 30px;
    line-height: 1.4em;
    letter-spacing: var(--w);
    width: var(--w);
    overflow: hidden;
    white-space: nowrap;
    color: #fafafa;
    text-shadow:
        calc(0*var(--w)) 0 #fafafa, calc(-1*var(--w)) 0 #fafafa, calc(-2*var(--w)) 0 #fafafa, calc(-3*var(--w)) 0 #fafafa, calc(-4*var(--w)) 0 #fafafa,
        calc(-5*var(--w)) 0 #fafafa, calc(-6*var(--w)) 0 #fafafa, calc(-7*var(--w)) 0 #fafafa, calc(-8*var(--w)) 0 #fafafa, calc(-9*var(--w)) 0 #fafafa;
    animation: l20 2s infinite linear;
}

.loader:before {
    content: "Loading...";
}

@keyframes l20 {
    9.09% {
        text-shadow:
            calc(0*var(--w)) -10px #fafafa, calc(-1*var(--w)) 0 #fafafa, calc(-2*var(--w)) 0 #fafafa, calc(-3*var(--w)) 0 #fafafa, calc(-4*var(--w)) 0 #fafafa,
            calc(-5*var(--w)) 0 #fafafa, calc(-6*var(--w)) 0 #fafafa, calc(-7*var(--w)) 0 #fafafa, calc(-8*var(--w)) 0 #fafafa, calc(-9*var(--w)) 0 #fafafa
    }

    18.18% {
        text-shadow:
            calc(0*var(--w)) 0 #fafafa, calc(-1*var(--w)) -10px #fafafa, calc(-2*var(--w)) 0 #fafafa, calc(-3*var(--w)) 0 #fafafa, calc(-4*var(--w)) 0 #fafafa,
            calc(-5*var(--w)) 0 #fafafa, calc(-6*var(--w)) 0 #fafafa, calc(-7*var(--w)) 0 #fafafa, calc(-8*var(--w)) 0 #fafafa, calc(-9*var(--w)) 0 #fafafa
    }

    27.27% {
        text-shadow:
            calc(0*var(--w)) 0 #fafafa, calc(-1*var(--w)) 0 #fafafa, calc(-2*var(--w)) -10px #fafafa, calc(-3*var(--w)) 0 #fafafa, calc(-4*var(--w)) 0 #fafafa,
            calc(-5*var(--w)) 0 #fafafa, calc(-6*var(--w)) 0 #fafafa, calc(-7*var(--w)) 0 #fafafa, calc(-8*var(--w)) 0 #fafafa, calc(-9*var(--w)) 0 #fafafa
    }

    36.36% {
        text-shadow:
            calc(0*var(--w)) 0 #fafafa, calc(-1*var(--w)) 0 #fafafa, calc(-2*var(--w)) 0 #fafafa, calc(-3*var(--w)) -10px #fafafa, calc(-4*var(--w)) 0 #fafafa,
            calc(-5*var(--w)) 0 #fafafa, calc(-6*var(--w)) 0 #fafafa, calc(-7*var(--w)) 0 #fafafa, calc(-8*var(--w)) 0 #fafafa, calc(-9*var(--w)) 0 #fafafa
    }

    45.45% {
        text-shadow:
            calc(0*var(--w)) 0 #fafafa, calc(-1*var(--w)) 0 #fafafa, calc(-2*var(--w)) 0 #fafafa, calc(-3*var(--w)) 0 #fafafa, calc(-4*var(--w)) -10px #fafafa,
            calc(-5*var(--w)) 0 #fafafa, calc(-6*var(--w)) 0 #fafafa, calc(-7*var(--w)) 0 #fafafa, calc(-8*var(--w)) 0 #fafafa, calc(-9*var(--w)) 0 #fafafa
    }

    54.54% {
        text-shadow:
            calc(0*var(--w)) 0 #fafafa, calc(-1*var(--w)) 0 #fafafa, calc(-2*var(--w)) 0 #fafafa, calc(-3*var(--w)) 0 #fafafa, calc(-4*var(--w)) 0 #fafafa,
            calc(-5*var(--w)) -10px #fafafa, calc(-6*var(--w)) 0 #fafafa, calc(-7*var(--w)) 0 #fafafa, calc(-8*var(--w)) 0 #fafafa, calc(-9*var(--w)) 0 #fafafa
    }

    63.63% {
        text-shadow:
            calc(0*var(--w)) 0 #fafafa, calc(-1*var(--w)) 0 #fafafa, calc(-2*var(--w)) 0 #fafafa, calc(-3*var(--w)) 0 #fafafa, calc(-4*var(--w)) 0 #fafafa,
            calc(-5*var(--w)) 0 #fafafa, calc(-6*var(--w)) -10px #fafafa, calc(-7*var(--w)) 0 #fafafa, calc(-8*var(--w)) 0 #fafafa, calc(-9*var(--w)) 0 #fafafa
    }

    72.72% {
        text-shadow:
            calc(0*var(--w)) 0 #fafafa, calc(-1*var(--w)) 0 #fafafa, calc(-2*var(--w)) 0 #fafafa, calc(-3*var(--w)) 0 #fafafa, calc(-4*var(--w)) 0 #fafafa,
            calc(-5*var(--w)) 0 #fafafa, calc(-6*var(--w)) 0 #fafafa, calc(-7*var(--w)) -10px #fafafa, calc(-8*var(--w)) 0 #fafafa, calc(-9*var(--w)) 0 #fafafa
    }

    81.81% {
        text-shadow:
            calc(0*var(--w)) 0 #fafafa, calc(-1*var(--w)) 0 #fafafa, calc(-2*var(--w)) 0 #fafafa, calc(-3*var(--w)) 0 #fafafa, calc(-4*var(--w)) 0 #fafafa,
            calc(-5*var(--w)) 0 #fafafa, calc(-6*var(--w)) 0 #fafafa, calc(-7*var(--w)) 0 #fafafa, calc(-8*var(--w)) -10px #fafafa, calc(-9*var(--w)) 0 #fafafa
    }

    90.90% {
        text-shadow:
            calc(0*var(--w)) 0 #fafafa, calc(-1*var(--w)) 0 #fafafa, calc(-2*var(--w)) 0 #fafafa, calc(-3*var(--w)) 0 #fafafa, calc(-4*var(--w)) 0 #fafafa,
            calc(-5*var(--w)) 0 #fafafa, calc(-6*var(--w)) 0 #fafafa, calc(-7*var(--w)) 0 #fafafa, calc(-8*var(--w)) 0 #fafafa, calc(-9*var(--w)) -10px #fafafa
    }
}