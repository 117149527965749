@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.cdnfonts.com/css/ica-rubrik-black');
@import url('https://fonts.cdnfonts.com/css/poppins');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');
@import 'react-notifications/lib/notifications.css';

.container {
  margin: 0 auto;
}

body {
  overflow: auto;
  font-family: "Noto Sans", sans-serif;
  color: #FDFDFD;
  letter-spacing: 2.5px;
  background: #1A1A1D;
}

.list-menu li {
  position: relative;
  white-space: nowrap;
}

.list-menu li::after {
  content: '';
  height: 4px;
  background: white;
  bottom: -10px;
  left: 0;
  width: 0;
  transition: 0.5s;
  position: absolute;
}

.list-menu li:hover::after {
  width: 100%;
  transition: 0.5s;
}

.menu-admin .active {
  color: #1A1A1D;
  background: #FAFAFA;
}

.menu-hover,
.cart-hover,
.login-hover {
  pointer-events: auto;
  cursor: pointer;
}

.login-hover::after {
  display: none;
}

.danhmuc-list,
.cart-list,
.login-list {
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  height: 0;
  overflow: hidden;

}

.login-list {
  right: 0
}

.login-hover:hover .login-list {
  opacity: 1;
  pointer-events: auto;
  transition: 0.5s;
  height: 300px;
}

.menu-hover:hover .danhmuc-list {
  opacity: 1;
  pointer-events: auto;
  transition: 0.5s;
  height: auto;
  min-height: 300px;
}

.cart-list {
  right: 0;
  width: 400px;
  height: 0;
  transition: 0.5s;

}

.cart-hover:hover .cart-list {
  min-height: 400px;
  height: auto;
  z-index: 100;
  opacity: 1;
  pointer-events: auto;
  transition: 0.5s;
  overflow: hidden;


}

.bg-gradient {
  background: #1A1A1D;
  background: radial-gradient(circle, rgba(14, 55, 70, 1) 1%, #1A1A1D 65%);
}

.box-shadow {
  box-shadow: 20px 50px 58px -2px rgba(0, 0, 0, 0.91);
  -webkit-box-shadow: 20px 50px 58px -2px rgba(0, 0, 0, 0.91);
  -moz-box-shadow: 20px 50px 58px -2px rgba(0, 0, 0, 0.91);
  bottom: 0;
}

.translate-rotate {
  transform: translateY(-50%) rotate(-90deg);
}

.bg-transparent {
  background: transparent;
}

.email-input {
  padding: 12px;
  border-bottom: 1px solid #fff;
  background: transparent;
}

.button-submit {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  width: auto;
  margin: 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 0;
  outline: 0;
  padding: 14px 69px 14px calc(69px - .02em);
  color: #fff;
  border: 1px solid #fff;
  cursor: pointer;
  font-weight: bold;
  justify-content: center;
}

.button-submit:after {
  content: '';
  display: block;
  width: 100%;
  height: 5px;
  background-color: currentColor;
  position: absolute;
  bottom: 0;
  left: 0px;
  pointer-events: none;
  transition: 0.3s;
}

.button-submit:hover::after {
  mix-blend-mode: difference;
  height: 100%;
  transition: 0.3s;
}

.button-review {
  position: relative;
}



.button-review:after {
  position: absolute;
  content: "";
  height: 4px;
  width: 0;
  bottom: 0;
  left: 4px;
  background: #FDFDFD;
  transition: 0.5s;
}

.button-review.active::after {
  width: calc(100% - 12px);
}

.button-review:hover:after {
  width: calc(100% - 12px);
  transition: 0.5s;
}

.header-mobile {
  transition: 0.5s;
}

.header-active {
  z-index: 100;
  transition: 0.5s;
  height: 330px;
  max-height: calc(100vh - 70px);
  transform: translateY(0);
}

.header-danhmuc-active {
  height: 500px;
}

.vector {
  transform: rotate(0);
  transition: 0.3s;
}

.vector-rotate {
  transform: rotate(90deg);
  transition: 0.3s;

}

.nav-danhmuc {
  height: 24px;
  transition: 0.5s;
}

.nav-danhmuc-active {
  height: 190px;
  transition: 0.5s;

}

.price-product {
  display: inline-block;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  z-index: 0;
  width: auto;
}

@media (min-width: 1024px) {
  .about-sticky {
    position: fixed;
    width: 370px !important;
  }

  .about-absolute {
    position: absolute;
    bottom: 0;
    width: 370px !important;

  }
}

@media (min-width: 1536px) {
  .about-sticky {
    position: fixed;
    width: 432px !important;
  }

  .about-absolute {
    position: absolute;
    bottom: 0;
    width: 432px !important;

  }
}


.price-product::after {
  left: 0;
  bottom: 0;
  content: "Add to cart";
  position: absolute;
  width: 100%;
  height: 0;
  display: block;
  color: transparent;
  background: #1A1A1D;
  transform: translateY(-20%);
  transition: 0.5s;

}

.price-product:hover::after {
  height: 100%;
  color: #FDFDFD;
  transform: translateY(0);
  transition: 0.5s;
}

.product-item {
  cursor: pointer;
}

.product-item:hover .price-product:after {
  height: 100%;
  color: #FDFDFD;
  transform: translateY(0);
  transition: 0.5s;
}


.slider h2 {
  opacity: 0;
  transform: translateX(100px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  transition-delay: 0.2s
}

.slider span {
  opacity: 0;
  transform: translateX(100px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  transition-delay: 0.5s
}

.slider.active h2 {
  opacity: 1;
  transform: translateX(0);

}

.slider.active span {
  opacity: 1;
  transform: translateX(0);

}

.ant-modal-title {
  font-size: 20px !important;
  text-transform: uppercase;
}

.ant-select-selector {
  border: none !important;
  font-size: 18px !important;
}





/* Sử dụng media query để thay đổi layout cho màn hình nhỏ hơn 1200px */


/* Sử dụng media query để thay đổi layout cho màn hình nhỏ hơn 900px */
@media (max-width: 900px) {}

/* Sử dụng media query để thay đổi layout cho màn hình nhỏ hơn 600px */
@media (max-width: 600px) {
  .container {
    flex-direction: column;
    padding: 0 24px;
  }

  .item {
    flex: 1 1 auto;
    margin: 5px;
  }

  iframe {
    width: 100vw;
    margin-left: -24px;
  }

}

@media (max-width: 765px) {
  p {
    font-size: 14px;
    text-align: justify;
  }


}

/* Sử dụng media query để thay đổi layout cho màn hình nhỏ hơn 390px */
@media (max-width: 390px) {
  .container {
    max-width: 100%;
    padding: 24px;
  }

  .item {
    padding: 10px;
  }


}

.translate-center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

}

.text-transparent {
  color: transparent;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px var(#fff);
  text-stroke: 1px var(#fff);
  background-image: var(--qode-title-bg-img, none);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  -webkit-background-clip: text;
  background-clip: text;
  margin: 0;
  letter-spacing: 0.5rem;
}

.text-transparent::after {
  position: absolute;
  inset: 0 0 0 0;
  content: attr(data-content);
  z-index: 2;
  -webkit-text-stroke: 2px #d2d2d2;
  color: transparent;
}

.header-search {
  width: 0;
  opacity: 0;
  transition: 0.3s;
}

.header-search-active {
  width: 100%;
  opacity: 1;
  transition: 0.3s;
  z-index: 10;
}

.search-product {
  height: 0;
  transition: 0.5s;
  overflow: hidden;
  padding: 16px 24px;
  top: 50px;
  z-index: 0;
}

.search-product-active {
  height: 400px;
  transition: 0.5s;
  overflow-y: auto;
  top: 96px;
  border-bottom: 1px solid #d1d5db;

}

@media (min-width: 767px) {
  .header-search-active {
    width: 400px;
  }
}